@charset "utf-8";

/* ---------------------------------------------
	Heading -- vertical
--------------------------------------------- */
.c-head__vertical {
	color: $color-vt;
	font-weight: $font-weight-bolder;
	@include font-size(22);
	letter-spacing: 0.1em;
	line-height: 1;
	margin-bottom: 2em;

	@include mq_down(sm) {
		font-size: vwsp(24);
		margin-bottom: 4em;
	}
	@include mq(xl) {
		width: 1em;
		line-height: 1.5;
		margin: auto;
		writing-mode: vertical-rl;
	}
}
/* ---------------------------------------------
	Heading -- section
--------------------------------------------- */
.c-head__sc {
	font-size: calc(30px + 20 * (100vw - 320px) / 1280);
	line-height: 1.4;
	letter-spacing: 0.01em;
	@include mq(xxxl) {
		@include font-size(50);
	}
	span {
		display: block;
	}
}

/* ---------------------------------------------
	Heading -- large
--------------------------------------------- */
.c-head__large {
	font-size: calc(28px + 16 * (100vw - 320px) / 960);
	letter-spacing: 0;
	line-height: 1.6;
	margin-bottom: 1.5em;
	@include mq_down(xs) {
		line-height: 1.4;
	}
	@include mq_down(xxs) {
		font-size: 8vw;
	}
	@include mq(xs) {
		margin-top: -0.5em;
	}
	@include mq(xl) {
		@include font-size(44);
	}
	&.serif {
		font-family: $font-family-serif;
	}
}

/* ---------------------------------------------
	Heading -- subtitle
--------------------------------------------- */
.c-head__sub {
	font-weight: $font-weight-bold;
	font-size: vwsp(16);
	margin-top: 3em;
	margin-bottom: 0.5em;
	@include mq(xs) {
		@include font-size(16);
	}
}

/* ---------------------------------------------
	Heading -- under bar
--------------------------------------------- */
.c-head__bar {
	height: 2em;
	color: $main-color;
	font-weight: $font-weight-bold;
	font-size: vwsp(19);
	letter-spacing: 0.1em;
	@include mq(xs) {
		@include font-size(17);
	}
	&::after {
		display: block;
		content: "";
		width: 1.5em;
		height: 2px;
		background: $main-color;
		margin-top: 4px;
	}
}
