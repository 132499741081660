@charset "utf-8";

/* ------------------------------
	Navigation -- global
------------------------------ */
.c-gnav__wrap {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 0;
	background: rgba($main-color, 0.9);
	visibility: hidden;
	opacity: 0;
	overflow-y: hidden;
	scrollbar-face-color: $scrollbar-face-color;
	scrollbar-track-color: $scrollbar-face-color;
	-webkit-overflow-scrolling: touch;
	transition: 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
	z-index: 0;

	.open & {
		height: 100vh;
		visibility: visible;
		overflow-y: scroll;
		opacity: 1;
		z-index: 3;
	}
	.c-btn__contactform {
		margin: auto;
	}
	address {
		width: calc(100% - 12vw);
		margin: 60px auto 150px;
		text-align: center;
		@include mq(lg) {
			margin-top: 132px;
			text-align: center;
		}
	}
}

.c-gnav__logo {
	width: 80%;
	max-width: 484px;
	max-width: 300px;
	margin-top: 120px;
	margin-right: auto;
	margin-left: auto;
	@include mq_down(xs) {
		margin-top: 20vw;
	}
}

.c-gnav {
	width: 118px;
	margin: 70px auto 72px;
	@include mq(lg) {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: calc(100% - 4vw);
		max-width: 1000px;
		margin-top: 165px;
		margin-bottom: 108px;
	}

	li {
		width: auto;
		list-style: none;
		text-align: center;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 48px;
		@include mq(lg) {
			margin-bottom: 0;
		}

		a {
			display: block;
			color: $main-color2;
			font-size: calc(13px + 3 * (100vw - 320px) / 680);
			font-weight: $font-weight-bold;
			line-height: 1.5;

			&:hover,
			&.active {
				color: $link-hover-color;
				opacity: 1;
			}
		}
	}
}

/* ------------------------------
	Navigation -- button
------------------------------ */
.c-nav__btn {
	cursor: pointer;
	outline: 0;
	position: relative;
	z-index: 12;

	div {
		position: relative;
		width: 5vw;
		height: 5vw;
		outline: 0;
		@include absolute_top_right($top: 3vw);

		@include mq(sm) {
			width: 42px;
			height: 42px;
			top: 1.5vw;
		}

		@include mq(lg) {
			top: 2vw;
		}

		@include mq(xl) {
			top: 17px;
		}

		span {
			display: block;
			width: 100%;
			height: 2px;
			background: $main-color2;
			position: absolute;
			left: 0;
			transition: all 0.25s;

			@include mq(sm) {
				height: 3px;
			}

			@include mq(xxl) {
				height: 4px;
			}

			&:nth-of-type(1) {
				top: 1px;

				@include mq(sm) {
					top: -1px;
				}

				@include mq(xl) {
					top: 0;
				}

				.l-header.open & {
					top: 50%;
					transform: rotate(45deg);
				}
			}

			&:nth-of-type(2) {
				top: 50%;
				margin-top: vwsp(1);

				@include mq(sm) {
					top: calc(50% - 2px);
					margin-top: 0;
				}
				@include mq(xl) {
					top: 50%;
				}
				@include mq(xxl) {
					top: calc(50% + 2px);
				}

				.l-header.open & {
					width: 0;
					left: 50%;
				}
			}

			&:nth-of-type(3) {
				bottom: -3px;

				@include mq(sm) {
					bottom: 0;
				}

				@include mq(xl) {
					bottom: -2px;
				}

				@include mq(xxl) {
					bottom: -8px;
				}

				.l-header.open & {
					background: $main-color2;
					top: 50%;
					transform: rotate(-45deg);
				}
			}
		}
	}
}
