@charset "utf-8";

/* ------------------------------
	Button -- contact form
------------------------------ */
.c-btn__contactform {
	display: block;
	width: 80%;
	max-width: 320px;
	height: 40px;
	color: $main-color;
	font-weight: $font-weight-bolder;
	letter-spacing: 0.1em;
	line-height: 40px;
	@include font-size(14);
	background: $main-bg-color;
	text-align: center;
	margin: auto;
	@include mq_down(lg) {
		max-width: 234px;
	}
}
/* ------------------------------
	Button -- privacy policy
------------------------------ */
.c-btn__privacy {
	display: block;
	width: 80%;
	max-width: 320px;
	height: 20px;
	color: $main-color2;
	border-left: 1px solid $main-color2;
	border-right: 1px solid $main-color2;
	font-weight: $font-weight-bolder;
	@include font-size(13);
	letter-spacing: 0.1em;
	line-height: 16px;
	text-align: center;
	margin: 18px auto;
	@include mq_down(lg) {
		max-width: 234px;
	}
}

/* ------------------------------
	Link -- read more
------------------------------ */
.c-more {
	width: 6vw;
	height: 2em;
	font-size: calc(12px + 4 * (100vw - 320px) / 1280);
	line-height: 1.6;
	letter-spacing: 0;
	border-bottom: 1px solid $main-color;
	position: absolute;
	left: 0;
	bottom: 18px;
	z-index: 2;
	@include mq_down(xxs) {
		font-size: 4.5vw;
	}
	&::after {
		display: block;
		content: "";
		width: 10px;
		height: 10px;
		border-top: 1px solid $main-color;
		border-right: 1px solid $main-color;
		transform: rotate(45deg);
		@include absolute_bottom_right($bottom: 35%, $right: 3px);
		transition: right 0.5s;
		@include mq_down(xxs) {
			width: 3vw;
			height: 3vw;
		}
	}
	&:hover {
		&::after {
			right: -3px;
		}
	}
	&.w {
		color: $main-color2;
		border-bottom-color: $main-color2;
		&::after {
			border-top-color: $main-color2;
			border-right-color: $main-color2;
		}
	}
}

/* ------------------------------
	Button -- more(news)
------------------------------ */
.c-btn__more {
	display: none;
	width: 150px;
	height: 3em;
	text-align: center;
	padding-top: 0.5em;
	border: 1px solid $main-color;
	float: right;
	margin-top: 100px;
	&.active {
		display: block;
	}
}
