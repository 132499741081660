@charset "utf-8";

/* ---------------------------------------------
	Header
--------------------------------------------- */
.l-header {
	width: 100%;
	height: 20vw;
	max-height: 140px;
	z-index: 11;
	background: $main-color;
	transition: 0.5s ease-in-out;
	position: fixed;
	top: 0;
	z-index: 1;
	transition: all 1s;
	@include mq(xl) {
		max-height: 160px;
	}

	@include mq(xxl) {
		height: 160px;
	}

	&__inner {
		width: calc(100% - 6vw);
		max-width: 1340px;
		height: 20vw;
		margin-top: 4vw;
		margin-right: auto;
		margin-left: auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		z-index: 11;

		@include mq(lg) {
			height: 45px;
			margin-top: 2.5vw;
		}

		@include mq(xxl) {
			height: 50px;
			margin-top: 40px;
		}
	}

	&__logo {
		width: 61vw;
		max-width: 454px;
		height: auto;
		max-height: 68px;
		margin-left: 1vw;
		line-height: 0.5;
		position: relative;
		z-index: 10;
		transition: all 0.8s;

		@include mq() {
			width: 55vw;
		}

		@include mq(xxxl) {
			width: 454px;
			height: 68px;
		}

		a {
			@include over;

			svg {
				display: inline;
				width: 100%;
				max-width: 522px;
				height: auto;
				margin-left: 0;
			}
		}
		.open & {
			z-index: 0;
			a {
				img {
					opacity: 0;
				}
			}
		}
	}
}
