@charset "utf-8";

/* ---------------------------------------------
	Break
--------------------------------------------- */
.u-br {
		&::before {
			content:"\A";
			white-space: pre;
		}
	&.sp {
		&::before {
			content:"\A";
			white-space: pre;
		}
	}
	&.tb,
	&.tbpc,
	&.pc {
		&::before {
			content:"";
			white-space: nowrap;
		}
	}
	@include mq() {
		&.sp {
			&::before {
				content:"";
				white-space: nowrap;
			}
		}
		&.tb,
		&.tbpc {
			&::before {
				content:"\A";
				white-space: pre;
			}
		}
	}
	@include mq(lg) {
		&.tb {
			&::before {
				content:"";
				white-space: nowrap;
			}
		}
		&.pc {
			&::before {
				content:"\A";
				white-space: pre;
			}
		}
	}
}
