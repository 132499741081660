@charset "utf-8";

/* ---------------------------------------------
	Key visual
--------------------------------------------- */
.c-keyvisual {
	width: 100vw;
	height: 57.5vw;
	@include bg("n", no, center, auto 100%);
	position: relative;

	@include mq(xs) {
		height: 45vw;
	}

	@include mq() {
		height: 30vw;
	}

	@include mq() {
		background-size: 100% auto;
	}

	@include mq(xxxl) {
		height: 480px;
	}

	.l-container {
		position: absolute;
		height: 16vw;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;

		@include mq() {
			height: 5vw;
		}

		@include mq(xxxl) {
			height: 120px;
		}
	}

	&__text {
		width: 100%;
		height: 100%;
		text-indent: -200%;
		overflow: hidden;
		@include absolute_bottom_left($bottom: -2px);
		@include bg("n", no, lb, auto 100%);
		@include mq() {
			background-size: auto 4.7vw;
			bottom: 0;
		}
		@include mq(xxl) {
			background-size: auto 68px;
		}
	}

	#greetings & {
		background-image: url($imgDir + "keyvisual/greetings.jpg");
		background-position: right 20% center;
		&__text {
			background-image: url($imgDir + "keyvisual/greetings_txt.svg");
			@include mq_down() {
				background-size: auto 8vw;
			}
		}
	}

	#company & {
		background-image: url($imgDir + "keyvisual/company.jpg");
		&__text {
			background-image: url($imgDir + "keyvisual/company_txt.svg");
			@include mq_down() {
				background-image: url($imgDir + "keyvisual/company_txt_s.svg");
				background-size: auto 16vw;
			}
		}
	}

	#business & {
		background-image: url($imgDir + "keyvisual/business.jpg");
		&__text {
			background-image: url($imgDir + "keyvisual/business_txt.svg");
			@include mq_down() {
				background-image: url($imgDir + "keyvisual/business_txt_s.svg");
				background-size: auto 16vw;
			}
		}
	}

	#contact & {
		background-image: url($imgDir + "keyvisual/contact.jpg");
	}

	#news & {
		background-image: url($imgDir + "keyvisual/news.jpg");
	}
}
