@charset "utf-8";

/* ---------------------------------------------
	News
--------------------------------------------- */
.p-news,
.p-top__news {
	article {
		padding-top: 3em;
		padding-bottom: 1em;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			margin: -3em auto 3em;
			background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
		}
		time {
			@include font-size(16);
			font-weight: $font-weight-bolder;
		}
		p {
			margin-top: 1em;
		}
		&:last-of-type {
			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				margin-top: 3em;
				background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
			}
		}
	}
}

.p-top__news {
	article {
		p {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			max-height: 2.4em;
		}
	}
}

.p-news {
	main {
		min-height: 1000px;
	}
	#newslist {
		article {
			& > a {
				display: none;
			}
			&:last-of-type,
			&:nth-of-type(10) {
				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					margin-top: 3em;
					background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
				}
			}
		}
		&.open {
			article {
				&:nth-of-type(10) {
					&::after {
						content: none;
					}
				}
			}
		}
	}
}
