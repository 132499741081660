@charset "utf-8";

/* ---------------------------------------------
	Area -- link
--------------------------------------------- */
.c-area__link {
	width: 100%;
	height: auto;
	background: $color-ge5;

	ul {
		@include mq(xs) {
			width: 350px;
			margin: auto;
		}
		@include mq() {
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin: 0;
		}
		li {
			width: 100%;
			max-width: 350px;
			height: 0;
			padding-bottom: 91%;
			font-weight: $font-weight-bold;
			line-height: 1.2;
			position: relative;
			margin-bottom: 60px;
			@include mq(xs) {
				height: 320px;
				padding-bottom: 0;
			}
			@include mq() {
				width: 25%;
				max-width: 320px;
				height: 0;
				padding-bottom: 26%;
				margin-bottom: 0;
			}
			@include mq(xl) {
				height: 320px;
				padding-bottom: 0;
			}
			&:last-child {
				@include mq_down() {
					margin-bottom: 0;
				}
			}
			div {
				width: 100%;
				height: 0;
				padding-bottom: 75%;
				margin-bottom: 7%;
				overflow: hidden;
				img {
					width: 100%;
					margin-bottom: vwsize(10);
					transition: all 0.3s;
				}
			}
			p {
				font-size: calc(16px + 2 * (100vw -320px) / 680);
				letter-spacing: 0.15em;
				span {
					font-weight: $font-weight-bolder;
					font-size: calc(18px + 2 * (100vw -320px) / 680);
					letter-spacing: 0.05em;
					@include break;
				}
			}
			a {
				@include over;
				z-index: 1;

				&:hover ~ div img {
					transform: scale(1.2);
				}
			}
		}
	}
}

/* ---------------------------------------------
	Area -- contact
--------------------------------------------- */
.c-area__contact {
	& > p {
		font-size: calc(14px + 2 * (100vw - 320px) / 680);
		@include mq(xs) {
			@include font-size(14);
			margin-top: -50px;
		}
		@include mq(lg) {
			margin-top: -100px;
		}
		@include mq(xl) {
			margin-top: 0;
		}
	}
	&__type {
		@include mq() {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		li {
			width: 100%;
			margin-top: 4vw;
			@include mq(lg) {
				margin-top: 40px;
			}
			p {
				width: 100%;
				font-size: calc(15px + 1 * (100vw - 320px) / 680);
				font-weight: $font-weight-bold;
				margin-top: 40px;
				margin-bottom: 10px;
				@include mq(xs) {
					@include font-size(15);
				}
				span:not(.u-must) {
					@include mq() {
						width: auto;
						float: right;
					}
				}
			}
			a {
				width: 100%;
				height: 40px;
				background: $color-g4f;
				color: $main-color2;
				@include font-size(18);
				font-weight: $font-weight-bolder;
				line-height: 15px;
				letter-spacing: 0.05em;
				padding-top: 12px;
				padding-right: 1em;
				padding-left: 1em;
				@include bg("n", no, left 1em center);
				position: relative;
				@include mq_down(xxs) {
					font-size: 5vw;
					line-height: 5vw;
				}
			}
			&:nth-child(1) {
				@include mq() {
					width: 47.5%;
				}
				a {
					background-image: url($imgDir + "common/icon_tel.svg");
					background-size: 26px auto;
					text-align: right;
				}
			}
			&:nth-child(2) {
				@include mq() {
					width: 47.5%;
				}
				a {
					background-image: url($imgDir + "common/icon_email.svg");
					background-size: 28px auto;
					padding-top: 12px;
					text-align: right;
				}
			}
			&:nth-child(3) {
				margin-top: 60px;
				@include mq(lg) {
					margin-top: 80px;
				}
				p {
					& > span {
						font-size: 75%;
						font-weight: $font-weight-base;
						margin-top: 0.5em;
						margin-bottom: 20px;
						display: block;
						@include mq(lg) {
							float: right;
						}
					}
				}
				a {
					@include font-size(19);
					line-height: 16px;
					@include mq_down(xxs) {
						font-size: 5vw;
						line-height: 5vw;
					}
				}
			}
		}
	}
}
