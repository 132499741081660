@charset "utf-8";

/* ---------------------------------------------
	Address
--------------------------------------------- */
address {
	color: $main-color2;
	line-height: 1.5;
	span {
		display: inline-block;
		font-weight: $font-weight-semibold;
		&:nth-of-type(1) {
			display: block;
			font-weight: $font-weight-bolder;
		}
	}
}
