@charset "utf-8";
// =================================================
// Setting
// =================================================
// width -------------------------------------------
$width-browser: 1600px;
$width-base: 1340px;
$width-main: 1280px;
$width-content: 640px;

// font --------------------------------------------
$font-family-base: "Commissioner", sans-serif;
$font-family-serif: "EB Garamond", serif;
$font-family-monospace: "Commissioner", sans-serif;
$font-size-base: 15;
$font-weight-base: 200;
$font-weight-semibold: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$letter-spacing: 0.05em;
$line-height-base: 2;

// color -------------------------------------------
$main-color: #000; //通常文字,ヘッダーフッター背景色,左見出し,強調文字,READ MORE,トップBUSINESS背景色
$main-color2: #fff; //反転文字
$color-g4f: #4f4f4f; //CONTACTボタン背景色
$color-g7f: #7f7f7f; //フッターCONTACTボタン下TEL・E-MAIL
$color-g9: #999; //トップCOMPANY写真左背景色
$color-gb: #bbb; //BUSINESSアイコン下文字
$color-ge5: #e5e5e5; //リンクエリア背景色
$color-gf1: #f1f1f1; //トップNEWS背景色
$color-g65b: #65666b; //COMPANY表
$color-g3c: #3c3c3c; //トップGREETINGS文
$color-vt: #231815; //サイド見出し

$main-bg-color: #fff;

$btn-nav-color: #fff;

$line-color: rgba(#231815, 0.4); // ドットライン
$line-color2: #000; // NEWSドットライン

$selection-color: #bbbbbb;
$scrollbar-face-color: #999;

// form color -------------------------------------------
$wht: #fff;
$blk: #000;
$inputbrdr: #f8f8f8;
$inputbk: #f8f8f8;
$box-shadow: #999;
$colored: #efefef;
$achroma: #f8f8f8;
$placeholder: #999;
$wrn: #edd5de;
$must: #990000;
$err: #990000;
$ok: #0c0;
$submit1: #333333;
$submit_txt: #fff;
$reset1: #fff;
$reset2: #333333;

// link --------------------------------------------
$link-color: #000;
$link-font-weight: 400;
$link-decoration: none;
$link-hover-color: #999;
$link-hover-decoration: underline;

// table -------------------------------------------
$table-cell-padding: 1em;
$table-caption-color: #000;

// margin ------------------------------------------
$dt-font-weight: 300;
$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;
$label-margin-bottom: 0;

// other -------------------------------------------
$enable-pointer-cursor-for-buttons: true;
$imgDir: "../images/";
