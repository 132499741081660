@charset "utf-8";

/* ---------------------------------------------
	Base
--------------------------------------------- */
* {
	margin: 0;
	padding: 0;
}

html,
body {
	width: 100%;
	height: 100%;
}

html {
	font-size: 62.5%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

body {
	font-family: $font-family-base;
	font-style: normal;
	font-weight: $font-weight-base;
	-webkit-font-feature-settings: "palt";
	font-feature-settings: "palt";
	color: $main-color;
	line-height: $line-height-base;
	letter-spacing: $letter-spacing;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
	font-smooth: antialiased;
}

body.fixed {
	position: fixed;
	width: 100%;
}

ul,
ol,
dl {
	padding-left: 0;
}

ul {
	list-style: none;
}

ol {
	list-style: decimal;
}

dt {
	font-weight: $font-weight-base;
}

header,
footer,
main {
	transform: translateZ(0);
	will-change: transform;
	position: relative;
	transition: transform 0.3s;
}

a {
	display: block;
	font-weight: $link-font-weight;
	transition: all 0.3s;
	outline: none;
	cursor: pointer;
	transition: 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);

	&:hover,
	&:focus {
		opacity: 0.6;
	}
}

p a {
	display: inline-block;
	color: $link-color;

	&:hover,
	&:focus {
		text-decoration: $link-hover-decoration;
	}
}

div > img {
	display: block;
}

* > img {
	width: 100%;
	max-width: 100%;
	height: auto;
	outline: none;
	vertical-align: middle;
}

img[src$=".svg"],
object[src$=".svg"] {
	max-width: 100%;
	width: 100%; /* IE対応 */
	height: auto;
}

a img {
	outline: none;
}

hr {
	margin: 3em 0;
	border: 0;
	border-top: 2px solid #333;
}

strong {
	font-size: 130%;
	font-weight: $font-weight-bold;
}

table {
	border-spacing: 0;
	width: 100%;
}

td,
th {
	padding: 0;
	text-align: left;
}

td:first-child,
th:first-child {
	padding-left: 0;
}

td:last-child,
th:last-child {
	padding-right: 0;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin: 3px 0.5em;
}

label {
	cursor: pointer;
}

section {
	width: 100%;
	margin: 0;
	overflow-x: hidden;
	position: relative;
}
