@charset "utf-8";

/* ---------------------------------------------
	Margin
--------------------------------------------- */
$margin-min: 1;
$margin-max: 10;

@for $i from $margin-min through $margin-max {
	.u-mb#{$i} {
		margin-bottom: #{$i}rem;
	}

	.u-ml#{$i} {
		margin-left: #{$i}rem;
	}

	.u-mr#{$i} {
		margin-right: #{$i}rem;
	}

	.u-mt#{$i} {
		margin-top: #{$i}rem;
	}
}

/* ---------------------------------------------
	Padding
--------------------------------------------- */
$padding-min: 1;
$padding-max: 10;

@for $i from $padding-min through $padding-max {
	.u-pb#{$i} {
		padding-bottom: #{$i}rem;
	}

	.u-pl#{$i} {
		padding-left: #{$i}rem;
	}

	.u-pr#{$i} {
		padding-right: #{$i}rem;
	}

	.u-pt#{$i} {
		padding-top: #{$i}rem;
	}
}