@charset "utf-8";

/* ---------------------------------------------
	List -- disc
--------------------------------------------- */
.c-list__disc {
	list-style: none;
	margin-left: 1.2em;
	text-indent: -1em;
	margin-top: -1.6em;
	li {
		&::before {
			content: "・";
			width: 0.5em;
			margin-right: 0.3em;
		}
	}
}

/* ---------------------------------------------
	List -- table
--------------------------------------------- */
.c-list__table {
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.5em;
		background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
	}
	@include mq(sm) {
		display: flex;
		flex-flow: row wrap;
	}
	dt {
		letter-spacing: 0.01em;
		padding-bottom: 1em;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1.5em;
			background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
		}
		@include mq(sm) {
			width: 13em;
			text-align: right;
			span {
				margin-right: 3em;
			}
		}
		@include mq(lg) {
			width: 15em;
			span {
				margin-right: 5em;
			}
		}
	}
	dd {
		padding-bottom: 1em;
		@include mq(sm) {
			width: calc(100% - 13em);
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1.5em;
				background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
			}
		}
		@include mq(lg) {
			width: calc(100% - 15em);
		}
		& > span {
			display: inline-block;
			margin-left: 0.5em;
		}
		ul.c-list__disc {
			margin-top: 0.5em;
			li {
				padding-bottom: 1em;
				position: relative;
				span {
					display: inline-block;
					padding-left: 1em;
					@include mq_down() {
						display: inline;
						padding-left: 0;
					}
				}
				@include mq(sm) {
					&::after {
						content: "";
						display: block;
						width: calc(100% + 1em);
						height: 1px;
						margin-left: auto;
						margin-right: auto;
						background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
						@include absolute_bottom_left($bottom: 0.5em, $left: -1em);
					}
					&:last-of-type {
						&::after {
							display: none;
						}
					}
				}
				@include mq() {
					span {
						width: 100%;
						margin-top: 0.5em;
						padding-top: 0.5em;
						position: relative;
						&::before {
							content: "";
							display: block;
							width: calc(100% + 1em);
							height: 1px;
							margin-left: auto;
							margin-right: auto;
							background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
							@include absolute_top_left($left: -1em);
						}
					}
				}
				&:last-of-type {
					padding-bottom: 0;
					margin-bottom: -0.5em;
				}
			}
		}
	}
}
