@charset "utf-8";

// layout width
$breakpoint-up: (
	"xxs": "screen and (min-width: 321px)",
	"xs": "screen and (min-width: 480px)",
	"sm": "screen and (min-width: 600px)",
	"md": "screen and (min-width: 767px)",
	"lg": "screen and (min-width: 1000px)",
	"xl": "screen and (min-width: 1280px)",
	"xxl": "screen and (min-width: 1450px)",
	"xxxl": "screen and (min-width: 1600px)",
) !default;

// max-width
$breakpoint-down: (
	"xxs": "screen and (max-width: 320px)",
	"xs": "screen and (max-width: 479px)",
	"sm": "screen and (max-width: 599px)",
	"md": "screen and (max-width: 766px)",
	"lg": "screen and (max-width: 999px)",
	"xl": "screen and (max-width: 1279px)",
	"xxl": "screen and (min-width: 1449px)",
	"xxxl": "screen and (max-width: 1599px)",
) !default;

@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoint-up, $breakpoint)} {
		@content;
	}
}

@mixin mq-down($breakpoint: md) {
	@media #{map-get($breakpoint-down, $breakpoint)} {
		@content;
	}
}
