@charset "utf-8";

/* ---------------------------------------------
	Business content
--------------------------------------------- */
.p-business {
	.c-head__large {
		font-weight: $font-weight-semibold;
	}
	&__content {
		margin-top: 150px;
		& > div {
			margin-top: vwsp(120);
			margin-bottom: 120px;
			position: relative;
			@include mq(sm) {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				margin-top: 0px;
				margin-bottom: 80px;
			}
			& > div {
				width: 100%;
				@include mq(sm) {
					width: calc(100% - 190px);
					position: relative;
				}
				& > p {
					font-weight: $font-weight-bold;
					color: $color-g9;
					line-height: 1;
					margin-bottom: 0;
					@include mq_down(sm) {
						@include absolute_top_left($top: -2em);
					}
				}
				div {
					@include mq(sm) {
						@include absolute_bottom_left;
					}
					p {
						line-height: 1.6;
						margin-bottom: 0.5em;
					}
					ul {
						margin-top: 1em;
						li {
							line-height: 1.6;
						}
					}
					.c-head__bar {
						margin-top: 2.3em;
					}
					&:first-of-type {
						@include mq_down() {
							position: relative;
						}
					}
				}
			}
		}
		figure {
			width: 160px;
			height: 130px;
			padding-top: 30px;
			text-align: center;
			background-image: repeating-linear-gradient(0deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px), repeating-linear-gradient(90deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px), repeating-linear-gradient(180deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px),
				repeating-linear-gradient(270deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px);
			background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
			background-position: 0 0, 0 0, 100% 0, 0 100%;
			background-repeat: no-repeat;
			@include mq(sm) {
				height: 150px;
			}
			@include mq(lg) {
				height: 140px;
			}

			img {
				width: 72%;
				height: auto;
				vertical-align: middle;
				margin: auto;
			}
		}
	}
}
