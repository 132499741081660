@charset "utf-8";

/* ---------------------------------------------
	Greetings
--------------------------------------------- */
.p-greetings {
	.c-head__large {
		letter-spacing: 0.05em;
		span {
			display: block;
		}
	}
}
