@charset "utf-8";

/* ---------------------------------------------
	Footer
--------------------------------------------- */
.l-footer {
	padding-top: 80px;
	padding-bottom: 32px;
	position: relative;
	z-index: 0;
	background: $main-color;
	@include mq_down() {
		padding-top: vwsp(80);
		padding-bottom: vwsp(32);
	}
	@include mq(lg) {
		padding-top: 156px;
		padding-bottom: 32px;
	}

	&__upper {
		@include mq(lg) {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		.l-footer {
			&__navi {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				width: 55%;
				max-width: 700px;

				@include mq_down(lg) {
					display: none;
				}

				li {
					width: auto;
					@include font-size(15);
					@include mq(xl) {
						@include font-size(18);
					}
					a {
						color: $main-color2;
						font-weight: $font-weight-bold;
						letter-spacing: 0.05em;
					}
				}
			}
			&__contact {
				width: 340px;
				margin-top: 5px;
				@include mq_down(lg) {
					display: none;
				}
				@include mq(xl) {
					width: 370px;
				}
				.c-btn__contactform {
					width: 100%;
					max-width: 336px;
					@include mq(xl) {
						max-width: 370px;
					}
				}
				ul {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					margin-top: 10px;
					li {
						color: $color-g7f;
						@include font-size(13);
						font-weight: $font-weight-bold;
						letter-spacing: 0;
						a {
							display: inline-block;
							color: $main-color2;
							@include font-size(13);
							font-weight: $font-weight-bold;
						}
					}
				}
			}
		}
	}

	&__lower {
		padding-bottom: 3vw;
		@include mq(lg) {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin-top: 135px;
			padding-bottom: 40px;
		}
		.l-footer {
			&__logo {
				width: 95%;
				max-width: 365px;
				height: 73px;
				margin: auto;
				@include mq(sm) {
					width: 60%;
					max-width: 360px;
				}
				@include mq(lg) {
					width: 36%;
					max-width: 430px;
					margin-left: 0;
				}
			}

			&__add {
				margin-top: 150px;
				text-align: center;
				@include mq_down(sm) {
					margin-top: 20vw;
				}
				@include mq(lg) {
					width: 340px;
					margin-top: 1.2em;
					text-align: left;
				}
				@include mq(xl) {
					width: 370px;
				}
				span {
					@include font-size(13);
					&:nth-of-type(1) {
						@include font-size(16);
					}
					@include mq_down(xxs) {
						font-size: 3.5vw;
						&:nth-of-type(1) {
							font-size: 4vw;
						}
					}
					@include mq(lg) {
						@include font-size(12);
					}
					@include mq(xl) {
						@include font-size(13);
					}
				}
			}
		}
	}

	&__copyright {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		@include mq_down() {
			padding-top: vwsp(16);
		}
		@include mq(lg) {
			width: calc(100% - 12vw);
			max-width: $width-main;
			padding-top: 35px;
			margin: auto;
		}
		&::before {
			content: "";
			display: block;
			width: 90%;
			height: 1px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 16px;
			background-image: repeating-linear-gradient(90deg, #fff 0px, #fff 2px, #262626 2px, #262626 4px);
			@include mq(lg) {
				width: 100%;
			}
		}
		a {
			color: $main-color2;
			@include font-size(11);
			font-weight: $font-weight-bold;
			letter-spacing: 0.15em;
			@include mq_down(lg) {
				display: none;
			}
		}
		p {
			color: $main-color2;
			font-family: $font-family-base;
			@include font-size(11);
			font-weight: $font-weight-bold;
			text-align: center;
			margin: auto;
			letter-spacing: 0.15em;
			@include mq_down(xs) {
				font-size: vwsp(11);
			}
			@include mq(lg) {
				@include font-size(12);
			}
			@include mq(lg) {
				margin: 0;
			}
			br {
				display: none;
				@media screen and (max-width: 369px) {
					display: block;
				}
			}
		}
	}
}
