@charset "utf-8";

/* ---------------------------------------------
	Home
--------------------------------------------- */
.p-top {
	.l-content__left {
		.c-head__sc {
			@include mq_down(xl) {
				margin-bottom: 100px;
			}
		}
	}
	.l-content__right {
		@include mq_down(xl) {
			padding-bottom: 150px;
		}
	}
	.l-content {
		position: relative;
		z-index: 0;
		h2,
		h3,
		p {
			position: relative;
			z-index: 1;
		}
	}
	h3 {
		font-weight: 500;
		font-size: calc(24px + 10 * (100vw - 320px) / 640);
		line-height: 1.6;
		@include mq_down(xxs) {
			font-size: 6vw;
		}
		@include mq(lg) {
			@include font-size(34);
		}
	}
	.c-more {
		width: 240px;
		@include mq_down(xs) {
			width: 52%;
		}
	}
	//- HOME > メインビジュアル
	&__mainvisual {
		&__bg {
			width: 100%;
			height: 0;
			padding-bottom: 70%;
			@include bg("home/mainvisual.jpg", no, cb, cover);
			position: relative;
			@include mq_down(xs) {
				height: vwsp(360);
				padding-bottom: 0;
			}
			@include mq(lg) {
				height: vwsize(900);
				max-height: 800px;
				padding-bottom: 0;
			}
			p {
				color: $main-color2;
				font-size: 3vw;
				font-weight: $font-weight-bolder;
				line-height: 1.5;
				width: 40vw;
				max-width: 640px;
				display: block;
				position: absolute;
				top: 50%;
				left: 28%;
				text-shadow: 2px 2px 3px #000;
				transform: translate(-50%, -50%);
				@include mq() {
					max-width: 1100px;
					font-size: 2.2vw;
					line-height: 1.8;
				}
				@include mq(xl) {
					max-width: 500px;
					font-size: 24px;
				}
			}
		}
		.l-container {
			padding-top: 80px;
			padding-bottom: 160px;
			@include mq_down(xs) {
				padding-top: 40px;
			}
			h2 {
				color: $color-g9;
				font-family: $font-family-serif;
				font-weight: $font-weight-bold;
				font-size: calc(24px + 34 * (100vw - 320px) / 1280);
				line-height: 1.4;
				@include mq_down(xxs) {
					font-size: 9vw;
				}
				@include mq(xxxl) {
					@include font-size(58);
				}
				span {
					@include mq_down(lg) {
						@include break;
					}
				}
			}
			p {
				font-weight: $font-weight-bolder;
				font-size: calc(15px + 9 * (100vw - 320px) / 1280);
				letter-spacing: 0.05em;
				line-height: 1.5;
				margin-top: -2.5em;
				margin-left: 1px;
				@include mq_down(xs) {
					@include font-size(13);
					margin-top: -2em;
				}
			}
			.c-more {
				bottom: 80px;
			}
		}
	}
	//- HOME > GREETINGS
	&__greetings {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 146%;
		@include bg("home/bg_greetings_s.jpg", no, cb, cover);
		overflow: hidden;
		@include mq_down(xxs) {
			height: auto;
			padding-bottom: 20%;
		}
		@include mq(xs) {
			height: auto;
			padding-top: 70px;
			padding-bottom: 70px;
		}
		@include mq() {
			height: 800px;
			padding-top: 100px;
		}
		@include mq(xl) {
			background-image: url($imgDir + "home/bg_greetings.jpg");
		}
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: rgba($main-color2, 0.3);
			@include centering;
			z-index: -1;

			@include mq() {
				width: vwsize(2000);
				max-width: 565px;
			}
		}
		.l-container {
			height: 0;
			padding-bottom: 115%;
			padding-top: 10%;
			text-align: center;
			z-index: 1;
			@include mq_down(xxs) {
				padding-bottom: 0;
				height: 450px;
			}
			@include mq(xs) {
				padding-top: 70px;
				padding-bottom: 0;
				height: 600px;
			}
			@include mq() {
				padding-top: 50px;
			}
			&::after {
				content: "";
				display: block;
				width: 80%;
				max-width: 370px;
				height: 100%;
				background: rgba($main-color2, 0.5);
				@include centering;
				z-index: 0;
				@include mq_down(xs) {
					max-width: 22em;
				}

				@include mq(xs) {
					height: 600px;
				}
			}
			.c-head__sc {
				@include mq() {
					margin-top: 20px;
				}
				@include mq(lg) {
					line-height: 30px;
				}
			}
			h3 {
				font-family: $font-family-serif;
				font-size: calc(27px + 11 * (100vw - 320px) / 1280);
				line-height: 1.3;
				margin-top: 2em;
				@include mq_down(xs) {
					font-size: 7vw;
					margin-top: 1.5em;
				}
				@include mq() {
					margin-bottom: 1.5em;
				}
				@include mq(xl) {
					letter-spacing: 0;
				}
				@include mq(xxxl) {
					@include font-size(38);
				}
				span {
					@include break;
				}
			}
			p {
				color: $main-color !important;
				font-size: calc(12px + 2 * (100vw - 320px) / 1280);
				letter-spacing: 0;
				@include mq_down(xs) {
					font-size: 3vw !important;
				}
				@include mq(xxl) {
					@include font-size(14);
				}
				span {
					display: inline-block;
					&:nth-of-type(2),
					&:nth-of-type(3) {
						@include mq() {
							display: inline;
						}
					}
				}
			}
			.c-more {
				right: 0;
				margin: auto;
				bottom: 40px;
				z-index: 2;
				text-align: left;
				@include mq_down(xs) {
					position: relative;
					margin-top: 6em;
				}
				@include mq_down(xxs) {
					width: 121px;
				}
				@include mq(xs) {
					bottom: 60px;
				}
				@include mq() {
					bottom: 50px;
				}
			}
		}
		.l-content {
			position: relative;
			z-index: 1;
			p {
				@include mq_down(xs) {
					font-size: vwsp(16);
				}
				.u-br {
					display: inline;
				}
			}
		}
	}
	//- HOME > BUSINESS CONTENT
	&__business {
		padding: 100px 0;
		.l-container {
			@include mq(xl) {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				padding-bottom: 70px;
			}
		}
		.c-head__sc {
			width: 100%;
			br {
				display: block;
				@include mq() {
					display: none;
				}
			}
		}
		.l-content {
			&__left {
				margin-top: 80px;
				@include mq(xl) {
					width: 50%;
					margin-top: 30px;
				}
				h3 {
					letter-spacing: 0;
					margin-bottom: 0.5em;
					@include mq(xl) {
					}
					span {
						display: block;
					}
				}
				p {
					@include mq_down(xs) {
						font-size: vwsp(16);
					}
					span {
						display: block;
					}
				}
			}
			&__right {
				margin-top: 60px;
				@include mq(xl) {
					width: 50%;
					margin-top: 30px;
				}
			}
		}
		&__content {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 340px;
			margin: auto;
			@include mq(sm) {
				max-width: 540px;
			}
			@include mq() {
				max-width: 680px;
			}
			@include mq(xl) {
				margin-top: 28px;
			}
			& > div {
				width: 42vw;
				height: 60vw;
				max-width: 160px;
				max-height: 226px;
				background: $main-color2;
				text-align: center;
				position: relative;
				margin-bottom: 4vw;
				@include mq(sm) {
					width: 32vw;
					height: 45.7vw;
					padding-bottom: 0;
				}
				@include mq() {
					width: 30vw;
					height: 43vw;
					max-width: 200px;
					max-height: 282px;
					padding-bottom: 0;
				}
				@include mq(xl) {
					width: calc(33% - 1vw);
					margin-bottom: 2vw;
				}
				figure {
					width: 70%;
					margin-top: 18%;
					margin-left: auto;
					margin-right: auto;
				}
				.c-head__bar {
					@include font-size(15);
					line-height: 1.3;
					@include absolute_bottom_center($bottom: 34%);
					@include mq_down(xxs) {
						font-size: 4vw;
					}
					@include mq(sm) {
						bottom: 36%;
					}
					@include mq() {
						@include font-size(13);
					}
					span {
						@include break;
					}
					&::after {
						width: 1.5em;
						margin: 0.5em auto 0;
					}
				}
				p {
					color: $main-color;
					@include absolute_bottom_center($bottom: 10%);
					@include mq(sm) {
						font-size: 1.5vw;
						bottom: 15%;
					}
					@include mq() {
						@include font-size(13);
					}
				}
			}
		}
	}
	//- HOME > COMPANY PROFILE
	&__company {
		position: relative;
		height: 0;
		padding-bottom: vwsp(630);
		@include bg("home/bg_company_s.jpg", no, cc, cover);
		overflow: hidden;
		@include mq(xs) {
			padding-bottom: 0;
			height: vwsize(1866);
		}
		@include mq(sm) {
			height: vwsize(1200);
			background-image: url($imgDir + "home/bg_company.jpg");
		}
		@include mq(lg) {
			padding-top: 100px;
			padding-bottom: 100px;
			height: 800px;
		}
		&::after {
			content: "";
			display: block;
			width: vwsp(310);
			height: 100%;
			background: $color-g9;
			mix-blend-mode: multiply;
			@include absolute_top_left;
			z-index: 0;
			@include mq(sm) {
				width: vwsize(800);
			}
			@include mq() {
				width: vwsize(750);
			}
			@include mq(lg) {
				width: calc(400px + 1vw);
			}
			@include mq(xl) {
				width: 460px;
			}
			@include mq(xxl) {
				width: 100%;
				left: -280px;
				transform: translate(-50%, 0);
			}
		}
		.l-container {
			height: 0;
			color: $main-color2;
			padding-bottom: 100%;
			padding-top: 10%;
			z-index: 1;
			@include mq(xs) {
				padding-top: 8%;
				padding-bottom: 0;
				height: 83%;
			}
			@include mq(sm) {
				padding-top: 0;
				height: vwsize(840);
			}
			@include mq() {
				padding-top: vwsize(40);
			}
			@include mq(lg) {
				height: 680px;
				padding-top: 60px;
			}
			&::before {
				content: "";
				display: block;
				width: calc(50% + 6vw);
				height: 100%;
				background: rgba($main-color, 0.3);
				mix-blend-mode: multiply;
				z-index: 1;
				@include absolute_top_left($left: -6vw);
				@include mq_down(xs) {
					width: vwsp(230);
				}
				@include mq(sm) {
					width: vwsize(680);
					height: 110%;
					top: -10%;
				}
				@include mq() {
					width: vwsize(600);
					top: -(vwsize(40));
				}
				@include mq(lg) {
					width: 320px;
					height: 600px;
					top: 0;
					left: -2vw;
				}
				@include mq(xl) {
					width: 340px;
				}
			}
			&::after {
				content: "";
				display: block;
				width: calc(50% + 6vw);
				height: 100%;
				background: rgba(#000, 0.2);
				mix-blend-mode: multiply;
				z-index: 0;
				@include absolute_top_left($left: -6vw);
				@include mq_down(xs) {
					width: vwsp(230);
				}
				@include mq(sm) {
					width: vwsize(680);
					height: 110%;
					top: -10%;
				}
				@include mq() {
					width: vwsize(600);
					top: -(vwsize(40));
				}
				@include mq(lg) {
					width: 320px;
					height: 600px;
					top: 0;
					left: -2vw;
				}
				@include mq(xl) {
					width: 340px;
				}
			}
			.c-more {
				bottom: 11%;
				@include mq() {
					bottom: 5%;
				}
				@include mq(lg) {
					bottom: 150px;
				}
			}
		}
		.l-content {
			position: relative;
			z-index: 2;
			.c-head__sc {
				br {
					@include mq_down(xl) {
						display: block;
					}
				}
			}
		}
	}
	&__news {
		background: $color-gf1;
	}
}
